var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-2",attrs:{"flat":"","height":"100%"}},[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-color-title body-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('span',{staticClass:"select-box-width mx-4"},[_c('location-select-box',{attrs:{"item-text":"name","item-value":"id","placeholder":"Location","dense":"","filled":"","hide-details":""},on:{"change":function($event){return _vm.getData()},"setDefaultLocationValue":function($event){_vm.params.location_id = $event}},model:{value:(_vm.params.location_id),callback:function ($$v) {_vm.$set(_vm.params, "location_id", $$v)},expression:"params.location_id"}})],1),_c('v-btn',{staticClass:"bg-secondary text-color-white",attrs:{"small":""},on:{"click":function($event){return _vm.MIXINS_GO_TO_PAGE('/experiences/create')}}},[_vm._v(" Create ")])],1),_c('v-card-text',[_c('div',{key:_vm.tableKey,ref:"devotionTable",staticClass:"mt-10"},[_c('v-data-table',{staticClass:"rounded-lg",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","loading":_vm.loading,"server-items-length":_vm.totalItems,"options":_vm.options},on:{"update:options":[function($event){_vm.options=$event},_vm.paginate]},scopedSlots:_vm._u([{key:"item.start_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("MIXINS_UTC_TO_LOCAL_TIMESTAMPS")(item.start_at))+" ")])]}},{key:"item.end_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("MIXINS_UTC_TO_LOCAL_TIMESTAMPS")(item.end_at))+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption text-no-wrap"},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.pastor_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption text-no-wrap"},[_vm._v(" "+_vm._s(item.pastor_name)+" ")])]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption text-no-wrap"},[_vm._v(" "+_vm._s(item.location.name)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mx-1",attrs:{"rounded":"","small":"","depressed":"","dark":"","color":"success"},on:{"click":function($event){return _vm.MIXINS_GO_TO_PAGE(("/experiences/" + (item.id)))}}},[_vm._v(" View ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"rounded":"","small":"","depressed":"","dark":"","color":"cyan"},on:{"click":function($event){return _vm.MIXINS_GO_TO_PAGE(("/experiences/update/" + (item.id)))}}},[_vm._v(" Edit ")]),_c('delete-item-dialog',{attrs:{"itemID":item.id,"setup":_vm.setupDeleteDialog,"deleteURL":_vm.componentURL,"event-name":_vm.eventName}})],1)]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }