<template>
  <v-card flat height="100%" class="rounded-lg pa-2">
    <v-card-title class="d-flex align-center">
      <span class="text-color-title body-1 font-weight-bold">
        {{ title }}
      </span>
      <v-spacer></v-spacer>
      <span class="select-box-width mx-4">
        <location-select-box
          v-model="params.location_id"
          item-text="name"
          item-value="id"
          placeholder="Location"
          dense
          filled
          hide-details
          @change="getData()"
          @setDefaultLocationValue="params.location_id = $event"
        ></location-select-box>
      </span>
      <v-btn
        small
        class="bg-secondary text-color-white"
        @click="MIXINS_GO_TO_PAGE('/experiences/create')"
      >
        Create
      </v-btn>
    </v-card-title>
    <v-card-text>
      <!-- <div>
        <v-tabs left>
          <v-tab
            v-for="(time, key) in times"
            :key="key"
            @click="setTime(time.value)"
          >
            {{ time.text }}
          </v-tab>
        </v-tabs>
      </div> -->
      <div ref="devotionTable" class="mt-10" :key="tableKey">
        <!-- <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          class="rounded-lg"
          :loading="loading"
          :options.sync="options"
          :server-items-length="20"
          @pagination="paginate"
        > -->
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          class="rounded-lg"
          :loading="loading"
          :server-items-length="totalItems"
          :options.sync="options"
          @update:options="paginate"
        >
          <template v-slot:[`item.start_at`]="{ item }">
            <span class="text-color-main caption text-no-wrap">
              {{ item.start_at | MIXINS_UTC_TO_LOCAL_TIMESTAMPS }}
            </span>
          </template>
          <template v-slot:[`item.end_at`]="{ item }">
            <span class="text-color-main caption text-no-wrap">
              {{ item.end_at | MIXINS_UTC_TO_LOCAL_TIMESTAMPS }}
            </span>
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <span class="text-color-main caption text-no-wrap">
              {{ item.title }}
            </span>
          </template>
          <template v-slot:[`item.pastor_name`]="{ item }">
            <span class="text-color-main caption text-no-wrap">
              {{ item.pastor_name }}
            </span>
          </template>
          <template v-slot:[`item.location`]="{ item }">
            <span class="text-color-main caption text-no-wrap">
              {{ item.location.name }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <span class="d-flex">
              <v-btn
                rounded
                small
                depressed
                dark
                color="success"
                class="mx-1"
                @click="MIXINS_GO_TO_PAGE(`/experiences/${item.id}`)"
              >
                View
              </v-btn>
              <v-btn
                rounded
                small
                depressed
                dark
                color="cyan"
                class="mx-1"
                @click="MIXINS_GO_TO_PAGE(`/experiences/update/${item.id}`)"
              >
                Edit
              </v-btn>
              <delete-item-dialog
                :itemID="item.id"
                :setup="setupDeleteDialog"
                :deleteURL="componentURL"
                :event-name="eventName"
              >
              </delete-item-dialog>
            </span>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Storage from "@/services/storage.js";
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";
import { DELETE_ITEM_EVENT_BUS } from "@/components/miscellaneous/dialogs/js/DeleteItemEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
// import moment from "moment";

const experienceRepository = RepositoryFactory.get("experience");

const DeleteItemDialog = () =>
  import("@/components/miscellaneous/dialogs/DeleteItemDialog.vue");

let storage = new Storage();

export default {
  components: {
    DeleteItemDialog,
  },
  data: () => ({
    selectedRow: -1,
    title: "Services",
    times: [
      {
        text: "Scheduled",
        value: "scheduled",
      },
      {
        text: "Past",
        value: "past",
      },
    ],
    headers: [
      {
        text: "Start At",
        value: "start_at",
        sortable: false,
      },
      {
        text: "End At",
        value: "end_at",
        sortable: false,
      },

      {
        text: "Title",
        value: "title",
        sortable: false,
      },
      {
        text: "Facilitator",
        value: "pastor_name",
        sortable: false,
      },
      {
        text: "Location",
        value: "location",
        sortable: false,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
      },
    ],
    items: [],
    tableKey: 0,
    loading: false,
    setupDeleteDialog: {
      button: {
        rounded: true,
        small: true,
        depressed: true,
        dark: true,
        class: "mx-1",
        icon: false,
        label: "Delete",
        isIcon: false,
      },
      card: {
        title: "Devotionals Delete Item",
      },
    },
    eventName: "reloadItems_experiences",
    componentURL: "api/experiences",
    options: {
      page: 1,
      itemsPerPage: 15,
    },
    params: {
      page: 1,
      per_page: 15,
      latest: true,
      with_location: true,
    },
    totalItems: 0,
  }),
  methods: {
    setLocationId() {
      let user = storage.get("user");

      //  || user.user_type === "admin"
      if (user.user_type === "superadmin") {
        return true;
      }

      this.params.location_id = storage.get("location_id");
      return;
    },
    async paginate() {
      this.params.page = this.options.page;
      this.params.per_page = this.options.itemsPerPage;

      await this.setLocationId();

      await this.getData();
    },
    getData() {
      this.loading = true;

      let params = { ...this.params };

      experienceRepository
        .get({ params })
        .then((response) => {
          console.log({ response });

          let {
            data: { responseData: data },
          } = response;

          this.totalItems = data.total;

          this.items = data.data;
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          // this.items = experienceRepository.getExperienceDummyData();
          this.loading = false;
        });
    },
    setTime(value) {
      this.tableKey++;

      this.items = [];

      this.params = {
        page: 1,
        per_page: 15,
      };

      if (value === "past") {
        delete this.params.latest;
      } else {
        this.params.latest = true;
      }

      this.getData();
    },
    reloadItemsEventBus() {
      GLOBAL_EVENT_BUS.$off(this.eventName);
      GLOBAL_EVENT_BUS.$on(this.eventName, () => {
        this.getData();
      });
    },
    reloadItemsDeleteEventBus() {
      DELETE_ITEM_EVENT_BUS.$off(this.eventName);
      DELETE_ITEM_EVENT_BUS.$on(this.eventName, () => {
        this.getData();
      });
    },
    resetSelectedEventBus() {
      GLOBAL_EVENT_BUS.$off("resetSelected_devotional");
      GLOBAL_EVENT_BUS.$on("resetSelected_devotional", () => {
        this.selectedRow = -1;
      });
    },
    setupEventBus() {
      this.reloadItemsEventBus();
      this.reloadItemsDeleteEventBus();
      this.resetSelectedEventBus();
    },
  },
  mounted() {
    // this.getData();
    this.setupEventBus();
  },
};
</script>
